// 
// profile.scss
//


.profile-user{
    background-image: url(../images/profile-bg-1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: -24px -24px 23px -24px;
    padding: 140px 0px;
    position: relative;
    &:after{
    background: linear-gradient(to bottom, rgba($dark, 0.5) 30%, $dark 100%);
    position: absolute;
    height: 100%;
    width: 100%;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    opacity: 0.5;
    content: "";

    }
}

.profile-content{
    position: relative;
    margin-top: -60px;
}